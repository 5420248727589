import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from "@capacitor/core";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
// root.tsx
import React, { useCallback, useEffect, useRef } from "react";
import reactAliceStyle from "react-alice-carousel/lib/alice-carousel.css?url";
import datePickerStyle from "react-date-range/dist/styles.css?url";
import datePickerDefaultStyle from "react-date-range/dist/theme/default.css?url";
import ReactGA from "react-ga4";
import carouselStyle from "react-responsive-carousel/lib/styles/carousel.min.css?url";
import { createHead } from "remix-island";

import { ChakraProvider } from "@chakra-ui/react";

import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  isRouteErrorResponse,
  redirect,
  useRouteError,
} from "@remix-run/react";

import { version } from "../package.json";
import ConditionalScrollRestoration from "./components/ConditionalScrollRestoration";
import { ErrorHandle } from "./components/ErrorHandle";
import { getMetaContent } from "./components/meta";
import { ChakraStyle } from "./libs/chakra";
import getConfig, { EnvironmentConfig } from "./libs/config";
import {
  type AvailableRegionType,
  I18nProvider,
  isLocaleValid,
  isRegionValid,
} from "./libs/i18n";
import aliceCarouselStyles from "./styles/aliceCarouselStyles.css?url";
import dateRangePickerStyle from "./styles/dateRangePicker.css?url";
import styles from "./styles/global.css?url";
import theme from "./theme";
import { redirectFromOldUrl } from "./utils/redirectFromOldUrl";
import { setRedirectUrl } from "./utils/routeMatch";
import type { LocaleInputType } from "./zeus";

const gaId = getConfig().REMIX_PUBLIC_GAID;

interface DocumentProps {
  children: React.ReactNode;
}
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Hong_Kong");
dayjs.extend(weekday);
dayjs.locale("zh-HK");
dayjs.extend(relativeTime);

export const loader = async ({
  request,
  params,
}: LoaderFunctionArgs & {
  params: {
    locale: LocaleInputType;
    region: AvailableRegionType;
    path: string | string[];
  };
}) => {
  if (!isRegionValid(params.region) || !isLocaleValid(params.locale)) {
    const newUrl = await redirectFromOldUrl(request.url);
    if (newUrl) return redirect(newUrl, 301);
  }

  // const url = new URL(request.url);

  // 处理不同情况下的重定向逻辑
  /**
   * https://timable.com/en
   * https://timable.com/zh
   */

  // if (
  //   !isRegionValid(params.region) &&
  //   !isLocaleValid(params.locale) &&
  //   isLocaleValid(params.region)
  // ) {
  //   return setRedirectUrl(params,request)
  // }

  /**
   * https://timable.com/hk
   * https://timable.com/mo
   */

  // if (isRegionValid(params.region) && !isLocaleValid(params.locale)) {
  //   return setRedirectUrl(params,request);
  // }

  /**
   * https://timable.com/map
   */

  if (isRegionValid(params.region) && isLocaleValid(params.locale)) return null;
  return setRedirectUrl(params, request);

  // if (
  //   !isRegionValid(params.region) &&
  //   !isLocaleValid(params.locale) &&
  //   !!url.pathname
  // ) {
  //   return setRedirectUrl(params,request,url.pathname);
  // }
  /**
   * https://timable.com/
   */
  // if (!isRegionValid(params.region) && !isLocaleValid(params.locale))
  //   return redirect(`/${fallbackRegion}/${fallbackLocale}`);
};

export const meta: MetaFunction = () => getMetaContent();

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: carouselStyle },
    { rel: "stylesheet", href: reactAliceStyle },
    { rel: "stylesheet", href: aliceCarouselStyles },
    { rel: "stylesheet", href: datePickerStyle },
    { rel: "stylesheet", href: datePickerDefaultStyle },
    { rel: "stylesheet", href: dateRangePickerStyle },
    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    { rel: "preconnect", href: "https://fonts.gstatic.com" },
  ];
};

export const Head = createHead(() => (
  <>
    <meta charSet="utf-8" />
    <meta name="version" content={version} />
    <Meta />
    <Links />
  </>
));

const Document = ({ children }: DocumentProps) => {
  return (
    <>
      <Head />
      <ChakraStyle />
      {children}
      <ConditionalScrollRestoration />
      <Scripts />
      {/* <LiveReload /> */}
      <EnvironmentConfig />
    </>
  );
};

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    console.log("ErrorBoundary", error);
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Document>
      <ChakraProvider resetCSS theme={theme}>
        <I18nProvider>
          <ErrorHandle error={error} />
        </I18nProvider>
      </ChakraProvider>
    </Document>
  );
}

function App() {
  // const [firebaseInitialized, setFirebaseInitialized] = useState(false);
  const firebaseInitialized = useRef(false);

  const onInitFirebase = useCallback(async () => {
    if (Capacitor.getPlatform() !== "web") return;
    const firebaseConfig = getConfig().REMIX_PUBLIC_FIREBASE_CONFIG;
    if (!firebaseConfig) return;
    if (firebaseInitialized.current === true) return;

    try {
      await FirebaseAnalytics.initializeFirebase(JSON.parse(firebaseConfig));
      firebaseInitialized.current = true;
    } catch (error) {
      console.error("Failed to initialize Firebase Analytics", error);
    }
  }, []);

  useEffect(() => {
    onInitFirebase();
  }, [onInitFirebase]);

  useEffect(() => {
    if (gaId)
      ReactGA.initialize([
        {
          trackingId: gaId,
          gaOptions: {}, // optional
          gtagOptions: {}, // optional
        },
      ]);
  }, []);

  return (
    <Document>
      <ChakraProvider resetCSS theme={theme}>
        <I18nProvider>
          <Outlet />
        </I18nProvider>
      </ChakraProvider>
    </Document>
  );
}

export default withSentry(App);
