import { theme as base } from '@chakra-ui/react';

const InputFieldStyle = {
    field: {
        borderRadius: "xl"
    }
}

export const ButtonStyles = {
    baseStyle: {
        // lineHeight: "9.2",
    },
    variants: {
        formButton: {
            backgroundColor: "timable.500",
            color: "white",
            borderRadius: "xl",
            height: 51,
            _hover: {
                backgroundColor: "#DD8C0A !important",
                color: "#FFF",
            },
        },
        link: {
            backgroundColor: "transparent",
            color: "#000",
            _hover: {
                textDecoration: "none",
                opacity: 0.75
            }
        },
        outlineButton: {
            backgroundColor: "transparent",
            borderRadius: "xl",
            color: "#000",
            _hover: {
                textDecoration: "none",
                opacity: 0.75
            }
        }
    },
    defaultProps: {
        colorScheme: "timable",
    },
};

export const InputStyles = {
    // baseStyle: {
    //     field: {
    //         bg: '#FFFFFF',
    //         borderColor: "brand.card-stroke",
    //         borderWidth: 1.5,
    //         borderRadius: "14px",
    //         ':focus': {
    //             borderColor: "brand.timable-yellow",
    //         },
    //         p: 6,
    //     }
    // },
    // sizes: {
    //     lg: InputFieldStyle,
    //     md: InputFieldStyle,
    //     sm: InputFieldStyle,
    //     xs: InputFieldStyle
    // },
    // variants: {
    //     filled: {
    //         field: {
    //             ...base?.components?.Input?.variants?.filled,
    //             border: "none",
    //             p: 0,
    //             ':focus': {
    //                 borderColor: "brand.timable-yellow",
    //             }
    //         }
    //     }
    // },
    // defaultProps: {
    //     variant: null
    // }
};

export const CheckboxStyles = {
    baseStyle: {
        control: {
            borderRadius: "99",
            borderColor: "inherit",
            color: "white",
            _focus: {
                ring: 2,
                ringColor: 'brand.timabe-yellow'
            }
        }
    }
};