import { FC } from "react";

import { Button, Stack, Text, VStack } from "@chakra-ui/react";

import { useFetcher } from "@remix-run/react";

import CustomImage from "./CustomImage";

import { useLocaleNavigate, useTranslation } from "~/libs/i18n";

export const ErrorHandle: FC<{
  caught?: any;
  error?: any;
}> = ({ error }) => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const fetcher = useFetcher();

  return (
    <Stack
      direction={["column"]}
      py={8}
      spacing={8}
      justifyContent="center"
      alignItems="center"
      mx="auto"
      className="errorHandle"
      maxW={340}
      textAlign={"center"}
    >
      <>
        <CustomImage
          width={341}
          height={281}
          src="/assets/images/member/eventNotFound.svg"
        />
        <VStack
          spacing={4}
          alignItems={["center", "center", "center"]}
          textAlign={"center"}
        >
          <Text
            fontSize="2xl"
            fontWeight={"bold"}
            style={{ fontFamily: "sans-serif", fontWeight: 700 }}
          >
            {t(`form.errors.${error?.status}`) || t(`form.errors.500`)}
          </Text>
          <Button
            onClick={() =>
              navigate(
                `?from=${typeof window === "undefined" ? "not-found" : window.location.href}`
              )
            }
            isLoading={fetcher.state === "loading"}
          >
            <Text fontWeight={700}>{t("backToHome")}</Text>
          </Button>
        </VStack>
      </>
    </Stack>
  );
};
