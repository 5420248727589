import {
  extendTheme,
  theme as base,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { ButtonStyles } from "~/theme/components";
const styles = {
  global: {
    ul: {
      listStyle: "none",
    },
  },
};

const theme = extendTheme(
  {
    styles,
    fonts: {
      heading: `'Noto Sans HK', sans-serif, ${base.fonts?.heading}`,
      body: `'Noto Sans HK', sans-serif, ${base.fonts?.heading}`,
    },
    colors: {
      brand: {
        "timable-yellow": "#F5A423",
        "gloomy-yellow": "#CA7C00",
        "secondary-yellow": "#DD8C0A",
        "soft-yellow": "#FFF9F0",
        "light-yellow": "#F8C779",
        "yellow-gradient":
          "linear-gradient(276.73deg, #F4A322 42.28%, #FFB931 96.03%)",
        grey: "#535353",
        "light-grey": "#F7F7F7",
        red: "#F46E22",
        "card-stroke": "#DDDDDD",
        "line-divider": "#C9C9C9",
        "link-blue": "#2C3EC2",
        green: "#38D188",
        "medium-grey": "#9F9F9F",
        linear: "linear-gradient(180deg, #FFF0D9 3.65%, #FFFFFF 21.88%)",
        "bg": "#F7F7F7",
      },
      timable: {
        300: "#F8C779",
        400: "#F8C779",
        500: "#F5A423",
        600: "#DD8C0A",
        700: "#CA7C00",
      },
    },
    components: {
      Box: {
        variants: {
          'mb-responsive': {
            px: [2,2,0],
          },
        },
      },
      Button: ButtonStyles,
      // Input: InputStyles,
      // Checkbox: CheckboxStyles
    },
  },
  withDefaultColorScheme({
    colorScheme: "timable",
    components: ["Button"],
  })
);
export default theme;
